import { useState } from "react";
import Modal from "react-modal";
import { FaStar } from "react-icons/fa";
import Swal from "sweetalert2";
Modal.setAppElement("#root");

const RatingModal = ({ isOpen, onClose, onSubmit }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState("");

  const handleStarClick = (value) => {
    setRating(value);
  };

  const handleSubmit = () => {
    if (rating === null) {
      Swal.fire({
        icon: "warning",
        title: "No Rating Selected",
        text: "Please select a rating before submitting.",
      });
      return;
    }
    onSubmit(rating, comment);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={modalStyles}
      contentLabel="Rate Your Experience"
    >
      <h2 style={modalStyles.title}>Rate Your Experience</h2>
      <div style={modalStyles.ratingContainer}>
        {[1, 2, 3, 4, 5].map((value) => (
          <FaStar
            key={value}
            size={40}
            color={value <= rating ? "#ffd700" : "#ccc"}
            style={{ cursor: "pointer" }}
            onClick={() => handleStarClick(value)}
          />
        ))}
      </div>
      <textarea
        placeholder="Write your comment..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        style={modalStyles.textarea}
      />
      <div style={modalStyles.buttons}>
        <button onClick={handleSubmit} style={modalStyles.button}>
          Submit
        </button>
        <button onClick={onClose} style={modalStyles.button}>
          Cancel
        </button>
      </div>
    </Modal>
  );
};

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    width: "300px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    margin: "auto",
    height: "fit-content",
  },
  title: {
    marginBottom: "15px",
  },
  ratingContainer: {
    display: "flex",
    marginBottom: "15px",
  },
  textarea: {
    width: "100%",
    height: "100px",
    resize: "none",
    marginBottom: "15px",
    border: "1px solid #ccc",
    padding: "10px",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  button: {
    backgroundColor: "#7f1d1d",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    padding: "10px",
    cursor: "pointer",
  },
};

export default RatingModal;
