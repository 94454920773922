import { useState, useEffect } from "react";
import api from "../api"; 
import { FaTimes, FaCheck } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

function RechargeModal({ onClose }) {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [rechargePacks, setRechargePacks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(null);
  const [selectedPack, setSelectedPack] = useState(null);

  useEffect(() => {
    const fetchRechargePacks = async () => {
      try {
        const response = await api.get("/recharge-packs");
        setRechargePacks(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setMessage("Failed to load recharge packs.");
        setIsSuccess(false);
      }
    };
    fetchRechargePacks();
  }, []);

  async function rechargeHandler() {
    if (!selectedPack) {
      setMessage("Please select a recharge pack.");
      setIsSuccess(false);
      return;
    }

    try {
      const response = await api.post(
        "/wallet/recharge",
        { packId: selectedPack },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.url) {
        window.location.href = response.data.url; // Redirect to payment gateway
      } else {
        setMessage("Failed to initiate payment.");
        setIsSuccess(false);
      }
    } catch (error) {
      console.error(error);
      setMessage("Recharge failed. Please try again.");
      setIsSuccess(false);
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full relative">
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-400 hover:text-gray-600 focus:outline-none"
        >
          <FaTimes size={18} />
        </button>
        <h2 className="text-xl font-semibold mb-4 text-center text-gray-700">
          {message ? "Recharge Status" : "Select a Recharge Pack"}
        </h2>
        {message ? (
          <div
            className={`mt-4 p-3 text-center rounded ${
              isSuccess
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            }`}
          >
            {message}
          </div>
        ) : (
          <>
            {isLoading ? (
              <div className="flex justify-center items-center py-6">
                <AiOutlineLoading3Quarters
                  className="animate-spin text-gray-600"
                  size={24}
                />
              </div>
            ) : (
              <ul className="divide-y divide-gray-200">
                {rechargePacks.map((pack) => (
                  <li
                    key={pack._id}
                    className={`cursor-pointer p-3 flex justify-between items-center hover:bg-gray-100 rounded transition duration-200 ${
                      selectedPack === pack._id ? "bg-gray-100" : ""
                    }`}
                    onClick={() => setSelectedPack(pack._id)}
                  >
                    <div className="flex items-center">
                      {selectedPack === pack._id && (
                        <FaCheck size={20} className="text-green-500 mr-2" />
                      )}
                      <span className="text-gray-800 font-medium">
                        {pack.name}
                      </span>
                    </div>
                    <span className="text-gray-500 font-semibold">
                      ₹{pack.price}
                    </span>
                  </li>
                ))}
              </ul>
            )}
            <button
              onClick={rechargeHandler}
              className="mt-6 bg-red-600 text-white py-2 px-6 rounded-full hover:bg-red-500 transition duration-200 w-full"
            >
              Recharge
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default RechargeModal;
