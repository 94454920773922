import React, { useState, useEffect, useRef } from "react";
import { useCall, useCallStateHooks } from "@stream-io/video-react-sdk";
import Swal from "sweetalert2";
import api from "../api";
import { useAuth } from "../contexts/AuthContext";

import { useNavigate } from "react-router-dom";
import RatingModal from "./RatingModal";

export const MyDescriptionPanel = () => {
  const { token } = useAuth();
  const { useCallCustomData, useCallSession, useCallEndedBy } =
    useCallStateHooks();
  const custom = useCallCustomData();
  const call = useCall();
  const callSession = useCallSession();
  const callEndedBy = useCallEndedBy();

  const [remainingTime, setRemainingTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(null);
  const [alertShown, setAlertShown] = useState(false);
  const [endedShown, setEndedShown] = useState(false);
  const [showRatingModal, setShowRatingModal] = useState(false);

  const intervalId = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (callSession) {
      const startTime = new Date(callSession.started_at);
      const endTime = new Date(callSession.timer_ends_at);

      const updateTimes = () => {
        const now = new Date();
        const timeElapsed = now - startTime;
        const timeRemaining = endTime - now;

        setElapsedTime(Math.max(0, timeElapsed));
        setRemainingTime(Math.max(0, timeRemaining));

        if (timeRemaining <= 60000 && timeRemaining > 0 && !alertShown) {
          Swal.fire({
            title: `Extend the appointment for ${
              (endTime - startTime) / 60000
            } minutes.`,
            text: "required amount will be deducted from your wallet",
            footer: "One minute remaining!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "OK",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#7f1d1d",
          }).then((result) => {
            if (result.isConfirmed) {
              extendAppointment();
            }
          });

          setAlertShown(true);
        }

        if ((timeRemaining <= 0 || callEndedBy) && !endedShown) {
          setEndedShown(true); // Update endedShown before showing alert

          Swal.fire({
            title: "Call has ended",
            text: "Thank you for your time.",
            icon: "info",
            showConfirmButton: false, // Hide the confirm button
            timer: 2000, // Auto close after 2 seconds
            timerProgressBar: true, // Show the progress bar
            footer: callEndedBy?.name
              ? `Call Ended By: ${callEndedBy?.name}`
              : "Call Time Expired",
            confirmButtonColor: "#7f1d1d",
          }).then(() => {
            clearInterval(intervalId.current); // Stop the interval when the call ends
            setShowRatingModal(true); // Show the custom rating modal
          });
        }
      };

      intervalId.current = setInterval(updateTimes, 1000);

      return () => clearInterval(intervalId.current);
    }
  }, [callSession, alertShown, callEndedBy, endedShown, token]);

  const submitReview = async (rating, comment) => {
    try {
      await api.post(
        `/review`,
        {
          callId: call.id,
          rating,
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Thank you for your feedback!",
        confirmButtonText: "OK",
        confirmButtonColor: "#7f1d1d",
      }).then(() => {
        navigate("/"); // Navigate to home page
      });
    } catch (error) {
      console.error("Error submitting review:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to submit review.",
        text: error.response.data.message,
      });
    }
  };

  const extendAppointment = async () => {
    try {
      await api.get(`/appointment/extend-call/${call.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Extending appointment...");
    } catch (error) {
      console.error("Error extending appointment:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to extend appointment.",
        text: error.response.data.message,
      });
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <div className="text-center mb-4">
      <h2 className="text-2xl font-bold text-white">{custom?.title}</h2>
      <p className="text-sm text-gray-100">{custom?.description}</p>
      {remainingTime !== null && elapsedTime !== null && (
        <div className="mt-2 text-white font-medium">
          <p>
            Time {formatTime(elapsedTime)} out of{" "}
            {formatTime(elapsedTime + remainingTime)}
          </p>
          <p>Remaining: {formatTime(remainingTime)}</p>
        </div>
      )}
      {showRatingModal && (
        <RatingModal
          isOpen={showRatingModal}
          onClose={() => {
            setShowRatingModal(false);
            navigate("/"); // Navigate to home page when modal is closed
          }}
          onSubmit={(rating, comment) => {
            submitReview(rating, comment);
            setShowRatingModal(false);
          }}
        />
      )}
    </div>
  );
};
