import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AuthProvider } from "./contexts/AuthContext.jsx";
import { ClientProvider } from "./contexts/ClientContext.jsx";
import { ChatProvider } from "./contexts/ChatContext";
import { WebSocketProvider } from "./contexts/WebSocketContext";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <WebSocketProvider>
      <ClientProvider>
        <ChatProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ChatProvider>
      </ClientProvider>
    </WebSocketProvider>
  </AuthProvider>
);
