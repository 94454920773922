import React, { createContext, useContext, useEffect, useState, useCallback } from "react";
import io from "socket.io-client";

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [chatEnded, setChatEnded] = useState(false);

  const initializeSocket = useCallback(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      // const socket = io("http://localhost:5000/", {
      //   query: { "user-id": user._id },
      // });
      const socket = io("https://astrologerdaddy.com/", {
        query: { "user-id": user._id },
      });

      socket.on("connect", () => {
        console.log("Socket.io connection established.");
      });

      socket.on("message", (data) => {
        console.log("Received Socket.io message:", data);
        
        const parsedData = typeof data === "string" ? JSON.parse(data) : data;
        console.log("Received Socket.io message:", parsedData);

        if (parsedData.type === "CHAT_MESSAGE") {
          setMessages((prevMessages) => [...prevMessages, parsedData.message]);
        } else if (
          parsedData.type === "END_CHAT" ||
          (parsedData.type === "ERROR" && parsedData.message === "Chat session has ended.")
        ) {
          console.log("Chat session has ended.");
          
          setChatEnded(true);
        }
      });

      socket.on("disconnect", () => {
        console.log("Socket.io connection closed.");
      });

      setSocket(socket);

      return () => {
        socket.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    const cleanupSocket = initializeSocket();
    return cleanupSocket;
  }, [initializeSocket]);

  const sendMessage = (message) => {
    if (socket) {
      console.log("Sending message:", message);
      socket.send(JSON.stringify(message));
    } else {
      console.error("Socket is not connected.");
    }
  };

  return (
    <WebSocketContext.Provider
      value={{
        socket,
        sendMessage,
        messages,
        setMessages,
        chatEnded,
        setChatEnded,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
