import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import api from "../api"; // Adjust path to your API utility
import { useParams } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

function PaymentResponse() {
  const [status, setStatus] = useState(null);
  const [message, setMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const params = useParams();
  const { token } = useAuth();

  useEffect(() => {
    const transactionId = params.transactionId;

    async function checkPaymentStatus() {
      try {
        const response = await api.get(
          `/wallet/payment-status/${transactionId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data.success) {
          setStatus(response.data.message);

          if (response.data.message === "Payment Successful") {
            Swal.fire({
              icon: "success",
              title: "Payment Successful",
              text: "Redirecting to transactions page...",
              timer: 2000,
              showConfirmButton: false,
            });
            setTimeout(() => {
              window.location.href = "/user/wallet";
            }, 2000);
          } else if (response.data.message === "Payment Pending") {
            setIsPending(true);
          } else {
            setIsPending(false);
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: response.data.message,
            });
          }
        } else {
          setStatus("Payment Failed");
          setIsPending(false);
          Swal.fire({
            icon: `${
              response.data.message === "Transaction already processed" ? "info" : "error"
            }`,
            title: "Payment Failed",
            text: response.data.message,
          });
        }
        setMessage(response.data.message);
      } catch (error) {
        console.error(error);
        setStatus("Payment Failed");
        setMessage("There was an error checking the payment status.");
        setIsPending(false);
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: "Oops Looks like your last transaction failed.",
        });
      }
    }

    // Show loading Swal before the initial check
    Swal.fire({
      title: "Checking payment status...",
      text: "Please wait while we confirm your payment.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    // Initial check
    checkPaymentStatus();

    let intervalId;
    let timeoutId;

    // If the payment is pending, check the status every 5 seconds for 2 minutes
    if (isPending) {
      intervalId = setInterval(checkPaymentStatus, 5000);

      // Stop checking after 2 minutes
      timeoutId = setTimeout(() => {
        clearInterval(intervalId);
        Swal.close();
      }, 120000); // 120000 ms = 2 minutes
    }

    // Clean up interval and timeout on component unmount
    return () => {
      clearInterval(intervalId);
      clearTimeout(timeoutId);
      Swal.close(); // Close the Swal if the component unmounts
    };
  }, [params.transactionId, token, isPending]);

  return (
    <div className="flex flex-col items-center justify-center  bg-gray-100 p-4">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md text-center">
        <h1 className="text-2xl font-semibold mb-4">Payment Status</h1>
        <p
          className={`text-xl font-bold ${
            status === "Payment Successful" ? "text-green-600" : "text-red-600"
          }`}
        >
          {status}
        </p>
        <p className="mt-2 text-gray-600">{message}</p>
        <a
          href="/"
          className="mt-4 inline-block px-4 py-2 bg-red-700 text-white rounded hover:bg-red-600"
        >
          Back to Home
        </a>
      </div>
    </div>
  );
}

export default PaymentResponse;
