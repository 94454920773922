import { useState, useEffect } from "react";
import api from "../api";
import RechargeModal from "../components/RechargeModal";
import Spinner from "../components/Spinner";
import { useAuth } from "../contexts/AuthContext";
import dayjs from "dayjs";

function Wallet() {
  const { token } = useAuth();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [balance, setBalance] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10); // items per page
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await api.get("/wallet/balance", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setBalance(response.data.balance);
      } catch (error) {
        console.error("Failed to fetch balance:", error);
        setError("Failed to fetch balance.");
      }
    };

    fetchBalance();
    fetchTransactions();
  }, [token, currentPage, limit, sortField, sortOrder]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchTransactions = async () => {
    setIsLoading(true);
    setError(""); // Clear previous errors
    try {
      const response = await api.get("/users/transactions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: currentPage,
          limit,
          sortField,
          sortOrder,
        },
      });

      setTransactions(response.data.transactions);
      setTotalPages(Math.ceil(response.data.totalCount / limit));
    } catch (error) {
      console.error("Failed to fetch transactions:", error);
      setError("Failed to fetch transactions.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  // Logic to render limited page numbers
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const halfWindow = Math.floor(maxPageNumbersToShow / 2);
    let startPage = Math.max(currentPage - halfWindow, 1);
    let endPage = Math.min(currentPage + halfWindow, totalPages);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
      startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-4 py-2 mx-1 text-white rounded ${
            currentPage === i
              ? "bg-yellow-600"
              : "bg-yellow-600 hover:bg-yellow-700"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="container w-11/12 md:w-5/6 mx-auto my-10">
      <div className="flex justify-between items-center mb-8 px-4">
        <p className="text-red-600 inline mt-4">
          Available Wallet Amount &#8377; <b>{balance || "0"}</b>
        </p>
        <button
          className="rounded px-4 py-2 font-medium text-red-600 text-center border-2 border-gray-400 focus:outline-none hover:bg-gray-200"
          onClick={() => setIsModalOpen(true)}
        >
          Recharge
        </button>
      </div>
      {isModalOpen && (
        <RechargeModal
          onClose={() => setIsModalOpen(false)}
          onRechargeSuccess={() => fetchTransactions()}
        />
      )}
      <p className="font-bold text-lg text-center md:text-left ml-2 mb-8">
        Transactions
      </p>

      {isLoading ? (
        <div className="flex justify-center my-20">
          <Spinner className="w-8 h-8" />
        </div>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : transactions.length === 0 ? (
        <p className="text-center text-gray-500">No data to show.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {transactions.map((transaction, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 border border-gray-200"
            >
              <div className="flex justify-between items-center mb-2">
                <p className="text-gray-700 font-medium">{transaction.type}</p>
                <p
                  className={`font-bold ${
                    transaction.status === "Success"
                      ? "text-green-600"
                      : transaction.status === "Pending"
                      ? "text-yellow-600"
                      : "text-red-600"
                  }`}
                >
                  {transaction.status}
                </p>
              </div>
              <div className="text-lg font-semibold text-black">
                &#8377; {transaction.amount.toFixed(2)}
              </div>
              <p className="text-gray-500 text-sm mt-2">
                {dayjs(transaction.transactionDate).format(
                  "DD/MM/YYYY hh:mm A"
                )}
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Pagination */}
      {transactions.length > 0 && (
        <div className="flex justify-center my-8">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 mx-1 text-white bg-orange-800 rounded disabled:bg-gray-400 hover:bg-orange-500"
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 mx-1 text-white bg-orange-800 rounded disabled:bg-gray-400 hover:bg-orange-500"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default Wallet;
