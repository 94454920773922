import React from "react";
import SectionHeading from "../components/SectionHeading";
import SectionSubHeading from "../components/SectionSubHeading";
import Slider from "react-slick";
import styles from "./Sliders.module.css";


const testimonials = [
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+1",
    text: "This is a wonderful service. Highly recommend! I have never experienced such dedication and professionalism from a service like this before. The staff were incredibly friendly and went out of their way to ensure I had all the information I needed. Truly exceptional and worth every penny.",
    name: "Aarav Sharma",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+2",
    text: "Amazing experience, very helpful! The insights provided were not only accurate but also extremely valuable. It has helped me make some critical decisions in my life. I would definitely recommend this to anyone looking for genuine guidance.",
    name: "Vihaan Kapoor",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+3",
    text: "Exceptional service and support. From the moment I signed up, I felt like I was in good hands. The team is highly knowledgeable and always available to answer any questions I had. Their predictions were spot on and provided a lot of clarity for my future plans.",
    name: "Vivaan Jain",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+4",
    text: "I was skeptical at first, but this service completely changed my perspective. The accuracy and depth of the readings were truly impressive. I felt understood and validated, which was a huge relief. I am now a regular user and couldn't be happier.",
    name: "Aadhya Patel",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+5",
    text: "The best experience I’ve ever had with a service of this kind. The staff are not only professional but also deeply empathetic. They take the time to understand your unique situation and offer personalized advice that is both practical and insightful.",
    name: "Aarohi Mehta",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+6",
    text: "I cannot thank the team enough for their guidance and support. Their insights have been invaluable in helping me navigate some tough times. The accuracy of their predictions is uncanny, and their advice is always spot on. Highly recommended!",
    name: "Aditya Gupta",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+7",
    text: "Absolutely fantastic! The level of detail and accuracy in the readings is second to none. I have gained so much clarity and confidence from their guidance. This service has truly been a game-changer for me.",
    name: "Anaya Rao",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+8",
    text: "A truly remarkable service that has provided me with so much insight and clarity. The team is incredibly knowledgeable and their readings are always accurate and detailed. I have recommended this service to all my friends and family.",
    name: "Aryan Nair",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+9",
    text: "I have tried many similar services in the past, but none compare to this one. The level of accuracy and the depth of the readings are unmatched. I feel more confident and assured about my future thanks to their guidance.",
    name: "Ishaan Reddy",
  },
  {
    avatar: "https://via.placeholder.com/150?text=Avatar+10",
    text: "This service has exceeded all my expectations. The staff are incredibly professional and their insights are always spot on. I feel like I have a clear path forward now, thanks to their guidance. I will definitely be using this service again.",
    name: "Kabir Singh",
  },
];

const GalleryPrevArrow = ({ currentSlide, slideCount, ...props }) => {
  const { onClick } = props;
  return (
    <div {...props} className={styles.customPrevArrow} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />
      </svg>
    </div>
  );
};

const GalleryNextArrow = ({ currentSlide, slideCount, ...props }) => {
  const { onClick } = props;
  return (
    <div {...props} className={styles.customNextArrow} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z" />
      </svg>
    </div>
  );
};

const settings = {
  className: styles.slickSlider,
  centerMode: false,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 3,
  speed: 500,
  nextArrow: <GalleryNextArrow />,
  prevArrow: <GalleryPrevArrow />,
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 940,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const TestimonialCard = ({ avatar, text, name }) => {
  return (
    <div className="bg-yellow-200 shadow-lg rounded-lg p-6 text-center mx-2  min-h-[350px]">
      <img className="w-16 h-16 rounded-full mx-auto" src={avatar} alt={name} />
      <p className="mt-2 text-gray-800 font-semibold">{name}</p>
      <p className="mt-4 text-gray-600">{text}</p>
    </div>
  );
};

const SliderComponent = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="flex items-stretch">
            <TestimonialCard
              avatar={testimonial.avatar}
              text={testimonial.text}
              name={testimonial.name}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

function CustomerStories() {
  return (
    <div className="my-4">
      <SectionHeading title={"Customer Stories"} />
      <SectionSubHeading
        title={
          "See why our users love us and how anytime Astrology Daddy helped them find their path to happiness!"
        }
      />
      <SliderComponent />
    </div>
  );
}

export default CustomerStories;
