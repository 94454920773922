import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import api from "../api"; // Adjust the path to your API utility
import dayjs from "dayjs";

const useOnCallChatClickHandler = (
  token,
  setLoading,
  minimumCallDuration,
  minutePrice
) => {
  const navigate = useNavigate();

  async function onCallChatClickHandler(id, type) {
    if (!id) {
      console.error("No astrologer ID provided");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `This ${type} requires a minimum of Rs ${
        minutePrice * minimumCallDuration
      } for consultation!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#eab308",
      cancelButtonColor: "#ef4444",
      confirmButtonText: "Yes, book it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Fetch user profile details
          const userProfile = await api.get("/users/profile", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          if (!userProfile || !userProfile.data) {
            console.error("Failed to fetch user profile", userProfile);
            return;
          }

          const {
            firstName = "",
            lastName = "",
            dob = "",
            birthPlace = "",
            birthTime = "",
          } = userProfile.data;

          const formatDate = (date) => {
            if (!date) return "";
            const d = new Date(date);
            const year = d.getFullYear();
            const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
            const day = String(d.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
          };

          // Show Swal form with pre-filled data
          const { value: formValues } = await Swal.fire({
            title: "Enter/Verify your details",
            html:
              '<div class="p-4 max-w-md mx-auto bg-white rounded-lg shadow-md">' +
              '<div class="mb-4">' +
              '<label for="swal-input1" class="block text-sm font-medium text-gray-700">First Name</label>' +
              '<input id="swal-input1" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm" placeholder="First Name" value="' +
              firstName +
              '">' +
              "</div>" +
              '<div class="mb-4">' +
              '<label for="swal-input2" class="block text-sm font-medium text-gray-700">Last Name</label>' +
              '<input id="swal-input2" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm" placeholder="Last Name" value="' +
              lastName +
              '">' +
              "</div>" +
              '<div class="mb-4">' +
              '<label for="swal-input3" class="block text-sm font-medium text-gray-700">Date of Birth</label>' +
              '<input id="swal-input3" type="date" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm" placeholder="Date of Birth" value="' +
              formatDate(dob) +
              '">' +
              "</div>" +
              '<div class="mb-4">' +
              '<label for="swal-input4" class="block text-sm font-medium text-gray-700">Birthplace</label>' +
              '<input id="swal-input4" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm" placeholder="Birthplace" value="' +
              birthPlace +
              '">' +
              "</div>" +
              '<div class="mb-4">' +
              '<label for="swal-input5" class="block text-sm font-medium text-gray-700">Birth Time</label>' +
              '<input id="swal-input5" type="time" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm" placeholder="Birth Time" value="' +
              birthTime +
              '">' +
              "</div>" +
              "</div>",

            focusConfirm: false,
            preConfirm: () => {
              const firstName = document.getElementById("swal-input1").value;
              const lastName = document.getElementById("swal-input2").value;
              const dob = document.getElementById("swal-input3").value;
              const birthPlace = document.getElementById("swal-input4").value;
              const birthTime = document.getElementById("swal-input5").value;

              if (
                !firstName ||
                !lastName ||
                !dob ||
                !birthPlace ||
                !birthTime
              ) {
                Swal.showValidationMessage("Please fill in all fields");
                return;
              }

              return { firstName, lastName, dob, birthPlace, birthTime };
            },
            showCancelButton: true,
            confirmButtonColor: "#eab308",
            cancelButtonColor: "#ef4444",
            confirmButtonText: "Submit",
            showLoaderOnConfirm: true,
            allowOutsideClick: () => !Swal.isLoading(),
          });

          if (formValues) {
            // Compare existing data with form values
            const detailsChanged =
              firstName !== formValues.firstName ||
              lastName !== formValues.lastName ||
              dayjs(dob).format("YYYY-MM-DD") !== formValues.dob ||
              birthPlace !== formValues.birthPlace ||
              birthTime !== formValues.birthTime;

            if (detailsChanged) {
              try {
                const response = await api.patch(
                  "/users/profile",
                  {
                    id,
                    ...formValues,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                if (!response || !response.data) {
                  console.error("Failed to save user details", response);
                  return;
                }

                Swal.fire({
                  title: "Success!",
                  text: "Your Details are saved.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2000,
                }).then(async () => {
                  setLoading(true); // Start the loading spinner

                  try {
                    const response = await api.post(
                      "/appointment/book-appointment",
                      { astrologerId: id, type },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    );

                    if (!response || !response.data) {
                      console.error("Failed to book appointment", response);
                      return;
                    }

                    // Polling function to check for appointment status
                    const pollForResponse = async () => {
                      const pollResponse = await api.get(
                        `/appointment/status/${response.data.appointment._id}`,
                        {
                          headers: {
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      );

                      if (
                        pollResponse.data.accepted === null ||
                        pollResponse.data.accepted === "Pending"
                      ) {
                        // Continue polling if still pending
                        setTimeout(pollForResponse, 3000);
                      } else {
                        // Stop loading and show the final status
                        setLoading(false);

                        if (pollResponse.data.accepted === "Accepted") {
                          Swal.fire({
                            title: `Appointment ${
                              type === "call" ? "call" : "chat"
                            } Accepted`,
                            text: "We are redirecting you to the meeting...",
                            icon: "success",
                            showConfirmButton: false,
                            timer: 2000,
                          }).then(() => {
                            navigate(
                              `/user/appointment/${response.data.appointment._id}`,
                              {
                                state: {
                                  userToken: pollResponse.data.token,
                                  type: type,
                                },
                              }
                            );
                          });
                        } else if (pollResponse.data.accepted === "Rejected") {
                          Swal.fire({
                            title: "Appointment Rejected",
                            text: "The astrologer has rejected your appointment request. Any money deducted will be refunded to your account.",
                            icon: "error",
                            confirmButtonColor: "#eab308",
                          });
                        }
                      }
                    };

                    pollForResponse();

                    Swal.fire({
                      title: "Please wait!",
                      text: "Your request is being processed by the astrologer.",
                      icon: "success",
                      showConfirmButton: false,
                      timer: 300000,
                      allowOutsideClick: false,
                      didOpen: () => {
                        Swal.showLoading();
                      },
                    });
                  } catch (error) {
                    console.error("Error booking appointment:", error);
                    setLoading(false);

                    Swal.fire({
                      title: "Error",
                      text:
                        error.response?.data?.message ||
                        "Something went wrong. Please try again.",
                      icon: "error",
                      confirmButtonColor: "#ef4444",
                    }).then(() => {
                      if (
                        error.response?.data?.message === "Insufficient balance"
                      ) {
                        navigate("/user/wallet");
                      }
                    });
                  }
                });
              } catch (error) {
                console.error("Request failed while saving details:", error);
                Swal.fire({
                  title: "Error",
                  text: `Request failed while saving details: ${error}`,
                  icon: "error",
                });
              }
            } else {
              // Proceed with booking appointment directly if no details changed
              setLoading(true); // Start the loading spinner

              try {
                const response = await api.post(
                  "/appointment/book-appointment",
                  { astrologerId: id, type },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                if (!response || !response.data) {
                  console.error("Failed to book appointment", response);
                  return;
                }

                // Polling function to check for appointment status
                const pollForResponse = async () => {
                  const pollResponse = await api.get(
                    `/appointment/status/${response.data.appointment._id}`,
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  if (
                    pollResponse.data.accepted === null ||
                    pollResponse.data.accepted === "Pending"
                  ) {
                    // Continue polling if still pending
                    setTimeout(pollForResponse, 3000);
                  } else {
                    // Stop loading and show the final status
                    setLoading(false);

                    if (pollResponse.data.accepted === "Accepted") {
                      Swal.fire({
                        title: `Appointment ${
                          type === "call" ? "call" : "chat"
                        } Accepted`,
                        text: "We are redirecting you to the meeting...",
                        icon: "success",
                        showConfirmButton: false,
                        timer: 1000,
                      }).then(() => {
                        // Navigate based on type
                        if (type === "call") {
                          navigate(
                            `/user/appointment/call/${response.data.appointment._id}`
                          );
                        } else if (type === "chat") {
                          navigate(
                            `/user/appointment/chat/${response.data.appointment._id}`
                          );
                        }
                      });
                    } else if (pollResponse.data.accepted === "Rejected") {
                      Swal.fire({
                        title: "Appointment Rejected",
                        text: "The astrologer has rejected your appointment request. Any money deducted will be refunded to your account.",
                        icon: "error",
                        confirmButtonColor: "#eab308",
                      });
                    }
                  }
                };

                pollForResponse();

                Swal.fire({
                  title: "Please wait!",
                  text: "Your request is being processed by the astrologer.",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 300000,
                  allowOutsideClick: false,
                  didOpen: () => {
                    Swal.showLoading();
                  },
                });
              } catch (error) {
                console.error("Error booking appointment:", error);
                setLoading(false);

                Swal.fire({
                  title: "Error",
                  text:
                    error.response?.data?.message ||
                    "Something went wrong. Please try again.",
                  icon: "error",
                  footer: error.response?.data?.waitTime,
                  confirmButtonColor: "#ef4444",
                }).then(() => {
                  if (
                    error.response?.data?.message === "Insufficient balance"
                  ) {
                    navigate("/user/wallet");
                  }
                });
              }
            }
          }
        } catch (error) {
          if (error.response.status === 401) {
            Swal.fire({
              title: "Login Required",
              text: "Redirecting to login page.",
              icon: "info",
              confirmButtonColor: "#ef4444",
            }).then(() => navigate("/login"));
            return;
          }

          Swal.fire({
            title: "Error",
            text: "Failed to fetch user profile. Please try again.",
            icon: "error",
          });
        }
      }
    });
  }

  return onCallChatClickHandler;
};

export default useOnCallChatClickHandler;
