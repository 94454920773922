import React, { useEffect, useState } from "react";
import { useWebSocket } from "../contexts/WebSocketContext";
import { useNavigate, useParams } from "react-router-dom";
import api from "../api";
import Swal from "sweetalert2";
import { useAuth } from "../contexts/AuthContext";
import dayjs from "dayjs";
import RatingModal from "../components/RatingModal";

function ChatPage() {
  const { ws, sendMessage, messages, setMessages, chatEnded, setChatEnded } =
    useWebSocket();
  const [newMessage, setNewMessage] = useState("");
  const [showRatingModal, setShowRatingModal] = useState(false);
  const [appointment, setAppointment] = useState(null);
  const [timeToExtend, setTimeToExtend] = useState(0);

  const params = useParams();
  const navigate = useNavigate();
  const appointmentId = params.chat_id;

  const { token } = useAuth();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const fetchAppointment = async () => {
      try {
        const response = await api.get(
          `/appointment/get-appointment/single/${appointmentId}?chats=true`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const appointment = response.data.appointment;

        if (appointment.status === "Completed") {
          setChatEnded(true);
          Swal.fire({
            icon: "warning",
            title: "Appointment Completed",
            text: "This appointment has already been completed.",
            confirmButtonText: "OK",
          }).then(() => {
            navigate("/user/appointments");
          });
        } else {
          // Reset messages and chat status when starting a new chat
          setMessages([]);
          setChatEnded(false);
        }

        const messages = response.data.messages.map((message) => {
          return {
            sender: message.onModel.toLowerCase(),
            text: message.text,
            time: dayjs(message.timestamp).format("DD/MM h:mm A"),
          };
        });

        setMessages(messages);
        console.log(response.data.appointment);

        setAppointment(response.data.appointment);
        setTimeToExtend(
          response.data.appointment.astrologer.minimumCallDuration
        );

        // Calculate time remaining and set one minute warning timer
        const currentTime = dayjs();
        const endsAt = dayjs(appointment.endsAt);
        const timeRemaining = endsAt.diff(currentTime);

        if (timeRemaining > 60000) {
          setTimeout(() => {
            handleOneMinuteRemaining(
              response.data.appointment.astrologer.minimumCallDuration
            );
          }, timeRemaining - 60000);
        }
      } catch (error) {
        console.error("Error fetching appointment:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while fetching the appointment details.",
          confirmButtonText: "OK",
        }).then(() => {
          navigate("/user/appointments");
        });
      }
    };

    fetchAppointment();
  }, [appointmentId, navigate]);

  useEffect(() => {
    if (ws) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received WebSocket message:", data);

        if (data.type === "CHAT_MESSAGE") {
          setMessages((prevMessages) => [...prevMessages, data.message]);
        } else if (
          data.type === "ERROR" &&
          data.message === "Chat session has ended."
        ) {
          setChatEnded(true);
        } else if (data.type === "END_CHAT") {
          setChatEnded(true);
        }
      };

      ws.onmessage = handleMessage;

      return () => {
        ws.onmessage = null; // Clean up WebSocket event listener
      };
    }
  }, [ws, setMessages]);

  const handleSendMessage = (e) => {
    e.preventDefault();
    if (newMessage.trim() === "" || chatEnded) return;

    const message = {
      type: "CHAT_MESSAGE",
      content: newMessage,
      appointmentId,
    };

    sendMessage(message);

    const time = new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: newMessage,
        sender: "user",
        time,
      },
    ]);

    setNewMessage("");
  };

  const extendAppointment = async () => {
    try {
      await api.get(`/appointment/extend-chat/${appointmentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("Extending appointment...");
      Swal.fire({
        title: "Appointment Extended",
        text: "The appointment has been successfully extended.",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#7f1d1d",
      });

      // Refetch appointment details to get the new end time and reset the timer
      const response = await api.get(
        `/appointment/get-appointment/single/${appointmentId}?chats=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const updatedAppointment = response.data.appointment;
      setAppointment(updatedAppointment);

      const currentTime = dayjs();
      const endsAt = dayjs(updatedAppointment.endsAt);
      const timeRemaining = endsAt.diff(currentTime);

      if (timeRemaining > 60000) {
        setTimeout(() => {
          handleOneMinuteRemaining(
            updatedAppointment.astrologer.minimumCallDuration
          );
        }, timeRemaining - 60000);
      }
    } catch (error) {
      console.error("Error extending appointment:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to extend appointment.",
        text: error.response.data.message,
        confirmButtonText: "OK",
        confirmButtonColor: "#7f1d1d",
      });
    }
  };

  const endChat = () => {
    const message = {
      type: "END_CHAT",
      appointmentId,
    };

    sendMessage(message);
    setChatEnded(true);

    Swal.fire({
      title: "Chat session has ended",
      text: "Thank you for your time.",
      icon: "info",
      showConfirmButton: false, // Hide the confirm button
      timer: 2000, // Auto close after 2 seconds
      timerProgressBar: true, // Show the progress bar
      confirmButtonColor: "#7f1d1d",
    }).then(() => {
      setShowRatingModal(true); // Show the custom rating modal
    });
  };

  const handleOneMinuteRemaining = (extensionTime) => {
    Swal.fire({
      title: `Extend the appointment for ${extensionTime || timeToExtend
        } minutes.`,
      text: "The required amount will be deducted from your wallet",
      footer: "One minute remaining!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#7f1d1d",
    }).then((result) => {
      if (result.isConfirmed) {
        extendAppointment();
      }
    });
  };

  const submitReview = async (rating, comment) => {
    try {
      await api.post(
        `/review`,
        {
          callId: appointmentId,
          rating,
          comment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Thank you for your feedback!",
        confirmButtonText: "OK",
        confirmButtonColor: "#7f1d1d",
      }).then(() => {
        navigate("/"); // Navigate to home page
      });
    } catch (error) {
      console.error("Error submitting review:", error);
      Swal.fire({
        icon: "error",
        title: "Failed to submit review.",
        text: error.response.data.message,
        confirmButtonText: "OK",
        confirmButtonColor: "#7f1d1d",
      });
    }
  };

  return (
    <div className="flex flex-col min-h-[84vh] lg:max-w-[1380px] lg:m-auto bg-gray-100 my-10">
      {/* Chat section */}
      <div className="flex-1 p-4 overflow-auto min-h-0">
        <div className="space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"
                }`}
            >
              <div
                className={`max-w-xs p-3 rounded-lg relative ${message.sender === "user"
                    ? "bg-yellow-400 text-white"
                    : "bg-gray-200 text-gray-900"
                  }`}
                style={{ minHeight: "60px" }}
              >
                <div className="flex flex-col">
                  <div className="text-sm font-bold mb-1">
                    {message.sender === "user"
                      ? "You"
                      : `${appointment.astrologer.name}`}
                  </div>
                  <div className="flex justify-between items-end gap-2">
                    <div className="whitespace-pre-wrap">{message.text}</div>
                    <div
                      className={`text-xs ${message.sender === "user"
                          ? "text-white"
                          : "text-gray-600"
                        }`}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {message.time}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Input section */}
      {chatEnded ? (
        <div className="p-4 border-t bg-white sticky bottom-0 text-center">
          <p className="text-red-500">Appointment has ended.</p>
        </div>
      ) : (
        <div className="p-4 border-t bg-white sticky bottom-0">

          <div className="flex items-center w-full space-x-2 overflow-hidden">
            <input
              type="text"
              className="min-w-0 flex-grow p-2 border border-yellow-400 rounded-lg outline-none focus:ring-2 focus:ring-yellow-500 ffocus:outline-none text-sm"
              placeholder="Enter your message..."
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleSendMessage(e) : null)}
            />
            <button
              onClick={handleSendMessage}
              className="px-2 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 text-sm flex-shrink-0"
            >
              Send
            </button>
            <button
              onClick={endChat}
              className="px-2 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 text-sm flex-shrink-0"
            >
              End Chat
            </button>
          </div>



        </div>
      )}

      {showRatingModal && (
        <RatingModal
          isOpen={showRatingModal}
          onClose={() => {
            setShowRatingModal(false);
            navigate("/");
          }}
          onSubmit={(rating, comment) => {
            submitReview(rating, comment);
            setShowRatingModal(false);
          }}
        />
      )}
    </div>
  );
}

export default ChatPage;
