


import React from 'react';
import { useParams } from 'react-router-dom';
import taurusImage from '../assets/zodiacs/taurus.webp';
import ariesImage from '../assets/zodiacs/aries.webp';

import cancerImage from "../assets/zodiacs/cancer.webp";
import pisceImage from "../assets/zodiacs/pisces.webp";
import virgo from "../assets/zodiacs/virgo.webp"
import ZodiacSection from '../sections/ZodiacSection';



const zodiacData = {
  taurus: {
    title: 'Taurus Today Horoscope',
    image: taurusImage,
    dates: '(Apr 20 - May 20)',
    predictions: {
      personal: 'Taureans can be very possessive, and that is often a toxic element in your relationships as you tend to never be satisfied and perceive threats where there are none. Keep your green-eyed monster under control; your jealousy is often not rational.',
      travel: 'Travel right now may be out of your control, so just relax and go with the flow.',
     
     Career:' Do not feel you need to do more for clients than they are paying you for. Turn off your phone and do not engage with clients today unless necessary. Taurus often do over and above what is called for, to their own detriment.',
     Health:'Taurus must learn to deal with change in the most personal areas of life. It is hard, but resisting will be bad for your psychological health.',
    Emotions:'Do not push away the very people who are able to give you comfort and reassurance.'
      
    }
  },
  aries: {
    title: 'Aries Today Horoscope',
    image: ariesImage,
    dates: '(Apr 20 - May 20)',
    predictions: {
      personal: 'aries can be very possessive, and that is often a toxic element in your relationships as you tend to never be satisfied and perceive threats where there are none. Keep your green-eyed monster under control; your jealousy is often not rational.',
      travel: 'Travel right now may be out of your control, so just relax and go with the flow.',
     
     Career:' Do not feel you need to do more for clients than they are paying you for. Turn off your phone and do not engage with clients today unless necessary. Taurus often do over and above what is called for, to their own detriment.',
     Health:'Taurus must learn to deal with change in the most personal areas of life. It is hard, but resisting will be bad for your psychological health.',
    Emotions:'Do not push away the very people who are able to give you comfort and reassurance.'
     
    }
  },
  cancer: {
    title: 'Cancer Today Horoscope',
    image: cancerImage,
    dates: '(Apr 20 - May 20)',
    predictions: {
      personal: 'cancer can be very possessive, and that is often a toxic element in your relationships as you tend to never be satisfied and perceive threats where there are none. Keep your green-eyed monster under control; your jealousy is often not rational.',
      travel: 'Travel right now may be out of your control, so just relax and go with the flow.',
     
     Career:' Do not feel you need to do more for clients than they are paying you for. Turn off your phone and do not engage with clients today unless necessary. Taurus often do over and above what is called for, to their own detriment.',
     Health:'Taurus must learn to deal with change in the most personal areas of life. It is hard, but resisting will be bad for your psychological health.',
    Emotions:'Do not push away the very people who are able to give you comfort and reassurance.'
      
    }
  },
  pisces: {
    title: 'Pisces Today Horoscope',
    image: pisceImage,
    dates: '(Apr 20 - May 20)',
    predictions: {
      personal: 'pisces can be very possessive, and that is often a toxic element in your relationships as you tend to never be satisfied and perceive threats where there are none. Keep your green-eyed monster under control; your jealousy is often not rational.',
      travel: 'Travel right now may be out of your control, so just relax and go with the flow.',
     
     Career:' Do not feel you need to do more for clients than they are paying you for. Turn off your phone and do not engage with clients today unless necessary. Taurus often do over and above what is called for, to their own detriment.',
     Health:'Taurus must learn to deal with change in the most personal areas of life. It is hard, but resisting will be bad for your psychological health.',
    Emotions:'Do not push away the very people who are able to give you comfort and reassurance.'
    
    }
  },
  virgo: {
    title: 'Virgo Today Horoscope',
    image: virgo,
    dates: '(Apr 20 - May 20)',
    predictions: {
      personal: 'virgo can be very possessive, and that is often a toxic element in your relationships as you tend to never be satisfied and perceive threats where there are none. Keep your green-eyed monster under control; your jealousy is often not rational.',
      travel: 'Travel right now may be out of your control, so just relax and go with the flow.',
     
     Career:' Do not feel you need to do more for clients than they are paying you for. Turn off your phone and do not engage with clients today unless necessary. Taurus often do over and above what is called for, to their own detriment.',
     Health:'Taurus must learn to deal with change in the most personal areas of life. It is hard, but resisting will be bad for your psychological health.',
    Emotions:'Do not push away the very people who are able to give you comfort and reassurance.'
    
    }
  },
};

function Horoscope() {
  const { zodiac } = useParams();
  const today = new Date().toLocaleDateString("en-GB", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const horoscope = zodiacData[zodiac.toLowerCase()];

  if (!horoscope) {
    return <div>Horoscope not found!</div>;
  }

  return (
    <div>
    <div className='bg-gradient-to-r from-yellow-400 via-orange-500 to-red-500 py-8 flex justify-center shadow-lg'>
        <div className='bg-white px-8 py-10 rounded-lg shadow-lg mr-12'>
    <p className='text-2xl font-bold text-gray-700 mb-6'>Select Other Sign</p>
    <input
        className='w-full px-4 py-2 border border-gray-300 rounded-lg mb-6 focus:outline-none focus:ring-2 focus:ring-red-400'
        type="text"
        placeholder="Search for a sign..."
    />
    <h1 className='text-xl font-semibold text-gray-700 mb-5'>Horoscopes:</h1>
    <ul className='space-y-4 text-gray-800'>
        <li className='text-lg font-semibold hover:text-red-500 cursor-pointer transition-colors duration-200'>Today Horoscope</li>
        <li className='text-lg font-semibold hover:text-red-500 cursor-pointer transition-colors duration-200'>Yesterday Horoscope</li>
        <li className='text-lg font-semibold hover:text-red-500 cursor-pointer transition-colors duration-200'>Tomorrow Horoscope</li>
        <li className='text-lg font-semibold hover:text-red-500 cursor-pointer transition-colors duration-200'>Weekly Horoscope</li>
        <li className='text-lg font-semibold hover:text-red-500 cursor-pointer transition-colors duration-200'>Yearly Horoscope</li>
    </ul>
</div>

      <div className='bg-white p-10 rounded-lg shadow-lg flex flex-col items-start max-w-2xl'>
        <div className='flex flex-col items-center justify-center mb-8'>
          <h1 className='text-5xl font-extrabold mb-4 text-gray-800'>{horoscope.title}</h1>
          <p className='text-lg font-medium text-gray-600 mb-6'>{today}</p>
          <img className='w-[150px] rounded-full' src={horoscope.image} alt={`${horoscope.title}`} />
        </div>
        <div className='text-gray-700 space-y-5'>
          <p>{horoscope.dates}</p>
          <p><strong>Personal:</strong> {horoscope.predictions.personal}</p>
          <p><strong>Travel:</strong> {horoscope.predictions.travel}</p>
         
          <p><strong>Career</strong>{horoscope.predictions.Career}</p>
          <p><strong>Money</strong>{horoscope.predictions.travel}</p>
          <p><strong>Money</strong>{horoscope.predictions.Emotions}</p>
        </div>
      </div>
   
    </div>
    <ZodiacSection />
    </div>
    
  );
}

export default Horoscope;


