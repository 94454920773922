import React, { useState, useEffect } from "react";
import SectionHeading from "../components/SectionHeading";
import SectionSubHeading from "../components/SectionSubHeading";
import AstrologerCard from "../components/AstrologerCard";
import ViewMoreButton from "../components/ViewMoreButton";
import api from "../api";
import { useNavigate } from "react-router-dom";

function OurAstrologersSection() {
  const navigate = useNavigate();

  const [astrologers, setAstrologer] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAstrologer = async () => {
      try {
        const response = await api.get("/astrologers", {
          params: {
            featured: true,
          },
        });
        setAstrologer(response.data.astrologers);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchAstrologer();
  }, []);

  return (
    <div className="py-8 w-full mx-auto bg-yellow-500">
      <SectionHeading title={"Our Astrologers"} />
      <SectionSubHeading
        title={
          "Get in touch with the best Online Astrologers, anytime & anywhere!"
        }
      />
      <div className="gap-4 mt-6 px-2 xl:w-5/6 mx-auto grid grid-cols-1 sm:grid-cols-3">
        {astrologers.map((astrologer, index) => (
          <AstrologerCard
            avatar={astrologer.profileImage}
            name={astrologer.name}
            id={astrologer._id}
            rating={astrologer.rating}
            experience={astrologer.experience}
            category={astrologer.category}
            languages={astrologer.languages}
            specializations={astrologer.specializations}
            minutePrice={astrologer.minutePrice}
            minimumCallDuration={astrologer.minimumCallDuration}
            availability={astrologer.availability}
            inCall={astrologer.inCall}
            inChat={astrologer.inChat}
            key={index}
          />
        ))}
      </div>
      <ViewMoreButton
        title={"View More"}
        click={() => navigate("/astrologers")}
      />
    </div>
  );
}

export default OurAstrologersSection;
