import React, { useState, useEffect } from 'react'

function KundliMatching() {
    const hours = Array.from({ length: 24 }, (_, i) => i+1);
  const minutes = Array.from({ length: 60 }, (_, i) => i ); // Generating minutes from 1 to 59
  const seconds = Array.from({ length: 60 }, (_, i) => i ); // Generating seconds from 1 to 59


  const days = [...Array(31).keys()].map(i => i + 1);
  const months = [...Array(12).keys()].map(i => new Date(0, i).toLocaleString('default', { month: 'long' }));
  const today = new Date();
  const todayYear = today.getFullYear();


  const [dateData, setDateData] = useState({ day: '', month: '', year: '' });
  const [years, setYears] = useState([...Array(100).keys()].map(i => todayYear - i));
  const [errors, setErrors] = useState('');

  useEffect(() => {
    const { day, month, year } = dateData;

   
    const getMaxYear = () => {
      const selectedMonthIndex = months.indexOf(month) + 1;
      const selectedDay = parseInt(day, 10);

      if (month && day) {
        const selectedDate = new Date(todayYear, selectedMonthIndex - 1, selectedDay);
        if (selectedDate > today) {
          return todayYear - 1; 
        }
      }
      return todayYear; 
    };

    const maxYear = getMaxYear();
    setYears([...Array(todayYear - (todayYear - 100) + 1).keys()].map(i => todayYear - i).filter(year => year <= maxYear));

    
    if (day && month && year) {
      const selectedDate = new Date(`${year}-${String(months.indexOf(month) + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`);
      if (selectedDate > today) {
        setErrors('The selected date cannot be greater than today.');
      } else {
        setErrors('');
      }
    }
  }, [dateData]);
  const handleDateUpdate = (e) => {
    const { name, value } = e.target;
    setDateData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const [formData, setFormData] = useState({
    boyName: "",
    girlName: "",
    gender: "",
    day: "",
    month: "",
    year: "",
    hour: "",
    minute: "",
    second: "",
    birth_place: "",
    
  });
  
  
  const [error, setError] = useState("");
  const [errorG, setErrorG] = useState("");
  const handleChangeB = (e) => {
    const { name, value } = e.target;
  
    if (name === 'girlName') { // Ensure it checks for 'nameG'
      if (value.length > 50) {
        setErrorG('Name should not exceed 50 characters');
        setFormData({
          ...formData,
          [name]: value.substring(0, 50),
        });
      } else {
        setErrorG('');
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  
  
  
  const handleSubmit = (e) => {
    e.preventDefault();
    const selectedDate = new Date(
      `${formData.year}-${formData.month}-${formData.day}`
    );
    const currentDate = new Date();

  
    // Check for valid date
    if (selectedDate > currentDate) {
      alert("The date cannot be in the future. Please select a valid date.");
      return;
    }

    
    setError('');
    console.log(formData);
  };



  const [formDataBoy, setFormDataBoy] = useState({
    name: "",
    hour: "",
    minute: "",
    second: "",
  });
  
  const [formDataGirl, setFormDataGirl] = useState({
    name: "",
    hour: "",
    minute: "",
    second: "",
  });
  
  const handleChangeBo = (e) => {
    const { name, value } = e.target;
    setFormDataBoy({
      ...formDataBoy,
      [name]: value,
    });
  };
  
  const handleChangeG = (e) => {
    const { name, value } = e.target;
    setFormDataGirl({
      ...formDataGirl,
      [name]: value,
    });
  };
  

  return (
    <div className=" mx-4 md:mx-20 my-4 lg:my-12 lg:mx-40">
      <h1 className="font-bold text-sm md:text-xl lg:text-3xl mt-2 text-center">
        Kundli-Matching Online
      </h1>
      <p className="text-center text-[10px] md:text-lg lg:text-xl mt-2 text-[#616161] font-semibold">
        Get instant & accurate, Kundli-Matching
      </p>
      <hr className="my-3" />
      <h1 className="text-[10px] md:text-lg lg:text-xl text-[#616161] font-semibold my-2 mx-auto">
        Free Kundli Online - Get Your Detailed Birth Chart with Predictions
      </h1>
      <p className="para mt-2 text-justify text-[10px] md:text-lg lg:text-
      xl mx-auto">
        Looking for your free Kundli from expert astrologers? Then you have come
        to the right place. The online free kundali available on Astrotalk is a
        100% free and authentic free Kundli that has been prepared after
        consulting more than 50 expert astrologers on board. The free kundli is
        such that it can give you a glimpse into various aspects of your life
        such as your career, love life, marriage, business and much more. The
        online kundli prepared by the free Kundali software here is no less than
        any traditional Kundli and can also be used for purposes like matching
        making, kundali matching for marriage or simply making future
        predictions.
      </p>
      <p className="mt-2 text-justify text-[10px] md:text-lg lg:text-xl mx-auto">
        Talking about future predictions, the kundali catered by Astrotalk to
        you is such that it considers the movement of all the planets in the
        native's life from the beginning of his or her life till as far as 100
        years. Doing so helps you understand the reasons behind not only the
        ongoing circumstances but also what's to come for you in the future. So
        if in any way you are confused about life, the online kundli can be your
        saviour. Having said that, make sure you try the free online kundli, and
        let us know what you feel about it.
      </p>
      <h2 className="text-sm md:text-lg lg:text-xl font-bold my-2">Kundli</h2>


   
      <div className="container p-3 max-w-full md:max-w-4xl mx-auto rounded-lg shadow-2xl border bg-white">
  <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-4">
    {/* Left Column */}
    <div className="max-w-lg mx-auto p-3 bg-white rounded-lg shadow-lg border border-gray-200">
      <div className="grid grid-cols-1 gap-4">
        {/* Title Button */}
        <div className="mb-3">
          <button className="text-lg border py-1 flex items-center justify-center bg-yellow-500 text-white w-full rounded-lg hover:bg-yellow-600 transition duration-200">
            Boy's Detail
          </button>
        </div>

        {/* Name Field */}
        <div className="mb-2">
  <label className="block text-gray-800 font-semibold text-lg mb-1">
    Boy's Name<span className="text-red-500 ml-1">*</span>
  </label>
  <input
    type="text"
    value={formData.boyName}
    onChange={handleChangeB}
    placeholder="Enter Boy's Name"
    min={50}
    name="boyName"
    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
    required
    pattern="[A-Za-z\s]+"
    title="Name should only contain letters."
  />
  {error && (
    <p className="text-red-500 mt-2">{error}</p> // error message
  )}
</div>

        {/* Birth Date */}
        <div className="mb-2">
          <p className="block text-gray-800 font-semibold text-lg mb-1">Birth Date</p>
          <div className="grid grid-cols-3 gap-2">
            {/* Day */}
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-1">
                Day<span className="text-red-500">*</span>
              </label>
              <select
                name="day"
                value={dateData.day}
                onChange={handleDateUpdate}
                className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                required
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>

            {/* Month */}
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-1">
                Month<span className="text-red-500">*</span>
              </label>
              <select
                name="month"
                value={dateData.month}
            onChange={handleDateUpdate}
                className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                required
              >
                <option value="">Month</option>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {/* Year */}
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-1">
                Year<span className="text-red-500">*</span>
              </label>
              <select
                name="year"
                value={dateData.year}
                onChange={handleDateUpdate}
                className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                required
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Time of Birth */}
        <div className="mb-4 grid grid-cols-3 gap-2">
  {/* Hour */}
  <div className="flex flex-col">
    <label className="block text-gray-700 font-semibold">
      Hour<span className="text-red-500">*</span>
    </label>
    <select
      name="boyHour" // Unique name for boy
      value={formData.boyHour}
      onChange={handleChangeB}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
      required
    >
      <option value="">Hour</option>
      {hours.map((hour) => (
        <option key={hour} value={hour}>
          {hour}
        </option>
      ))}
    </select>
  </div>

  {/* Minute */}
  <div className="flex flex-col">
    <label className="block text-gray-700 font-semibold">
      Minute<span className="text-red-500">*</span>
    </label>
    <select
      name="boyMinute" // Unique name for boy
      value={formData.boyMinute}
      onChange={handleChangeB}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
      required
    >
      <option value="">Minute</option>
      {minutes.map((minute) => (
        <option key={minute} value={minute}>
          {minute}
        </option>
      ))}
    </select>
  </div>

  {/* Second */}
  <div className="flex flex-col">
    <label className="block text-gray-700 font-semibold">
      Second<span className="text-red-500">*</span>
    </label>
    <select
      name="boySecond" // Unique name for boy
      value={formData.boySecond}
      onChange={handleChangeB}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
      required
    >
      <option value="">Second</option>
      {seconds.map((second) => (
        <option key={second} value={second}>
          {second}
        </option>
      ))}
    </select>
  </div>
</div>




        {/* Birth Place */}
        <div className="mb-3">
          <label className="block text-gray-800 font-semibold text-lg mb-1">
            Birth Place<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            maxLength="25"
            placeholder="Enter Birth Place"
            name="birth_place"
            className="p-2 border w-full border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
            required
            pattern="[A-Za-z\s]+"
            title="Birth Place should only contain letters."
          />
        </div>
      </div>
    </div>
    {/* Right Column - Similar adjustments can be made for the second form */}
    <div className="max-w-lg mx-auto p-3 bg-white rounded-lg shadow-lg border border-gray-200">
      <div className="grid grid-cols-1 gap-4">
        {/* Title Button */}
        <div className="mb-3">
          <button className="text-lg border py-1 flex items-center justify-center bg-yellow-500 text-white w-full rounded-lg hover:bg-yellow-600 transition duration-200">
            Girl's Detail
          </button>
        </div>

        {/* Name Field */}
        <div className="mb-2">
  <label className="block text-gray-800 font-semibold text-lg mb-1">
    Girl's Name<span className="text-red-500 ml-1">*</span>
  </label>
  <input
    type="text"
    value={formData.girlName}
    onChange={handleChangeB}
    min={50}
    placeholder="Enter Girl's Name"
    name="girlName"
    className="w-full p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
    required
    pattern="[A-Za-z\s]+"
    title="Name should only contain letters."
  />
   {errorG && (
                  <p className="text-red-500 mt-2">{error}</p> // error message
                )}
</div>


        {/* Birth Date */}
        <div className="mb-2">
          <p className="block text-gray-800 font-semibold text-lg mb-1">Birth Date</p>
          <div className="grid grid-cols-3 gap-2">
            {/* Day */}
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-1">
                Day<span className="text-red-500">*</span>
              </label>
              <select
                name="day"
                className="p-2 border  rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                required
              >
                <option value="">Day</option>
                {days.map((day) => (
                  <option key={day} value={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>

            {/* Month */}
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-1">
                Month<span className="text-red-500">*</span>
              </label>
              <select
                name="month"
                className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                required
              >
                <option value="">Month</option>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            {/* Year */}
            <div className="flex flex-col">
              <label className="text-gray-700 font-semibold mb-1">
                Year<span className="text-red-500">*</span>
              </label>
              <select
                name="year"
                className="p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition duration-300"
                required
              >
                <option value="">Year</option>
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        {/* Time of Birth */}
        <div className="mb-4 grid grid-cols-3 gap-2">
  {/* Hour */}
  <div className="flex flex-col">
    <label className="block text-gray-700 font-semibold">
      Hour<span className="text-red-500">*</span>
    </label>
    <select
      name="girlHour" // Unique name for girl
      value={formData.girlHour}
      onChange={handleChangeB}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
      required
    >
      <option value="">Hour</option>
      {hours.map((hour) => (
        <option key={hour} value={hour}>
          {hour}
        </option>
      ))}
    </select>
  </div>

  {/* Minute */}
  <div className="flex flex-col">
    <label className="block text-gray-700 font-semibold">
      Minute<span className="text-red-500">*</span>
    </label>
    <select
      name="girlMinute" // Unique name for girl
      value={formData.girlMinute}
      onChange={handleChangeB}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
      required
    >
      <option value="">Minute</option>
      {minutes.map((minute) => (
        <option key={minute} value={minute}>
          {minute}
        </option>
      ))}
    </select>
  </div>

  {/* Second */}
  <div className="flex flex-col">
    <label className="block text-gray-700 font-semibold">
      Second<span className="text-red-500">*</span>
    </label>
    <select
      name="girlSecond" // Unique name for girl
      value={formData.girlSecond}
      onChange={handleChangeB}
      className="p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
      required
    >
      <option value="">Second</option>
      {seconds.map((second) => (
        <option key={second} value={second}>
          {second}
        </option>
      ))}
    </select>
  </div>
</div>


        {/* Birth Place */}
        <div className="mb-3">
          <label className="block text-gray-800 font-semibold text-lg mb-1">
            Birth Place<span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            maxLength="25"
            placeholder="Enter Birth Place"
            name="birth_place"
            className="p-2 border w-full border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500 transition"
            required
            pattern="[A-Za-z\s]+"
            title="Birth Place should only contain letters."
          />
        </div>
      </div>
    </div>
  </form>

  {/* Submit Button */}
  <div className="flex items-center justify-center w-full p-2">
    <button className="w-full p-2 text-white text-lg bg-orange-500 hover:text-white rounded-lg hover:bg-orange-600">
      Match Horoscope
    </button>
  </div>
</div>



      


      <div class="future">
        <h1 class="font-bold text-sm md:text-xl lg::text-2xl  mt-4 text-center">
          Online Free Kundli For Future Predictions
        </h1>
        <p class="text-center text-[10px] md:text-lg lg::text-xl mt-2 text-[#616161] font-semibold ">
          Get Your Kundli by Date of Birth
        </p>

        <p class="para mt-3 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          The Kundli of a person is one of the most sought aspects of astrology.
          A Kundali entails the details about the positions of stars and planets
          at the time of your birth based on which further predictions are made.
          And with further predictions, we mean predictions about different
          aspects of one's life like love, career, health, business, finance,
          and even marriage. Simply said, Kundli making is a necessary thing for
          any individual and is likely to improve their decision-making
          capabilities gradually in the course of time.
        </p>
        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          A Janam Kundli is made after studying the exact position of planets at
          the time you were born and based on the same, one’s Sun sign, Moon
          sign, and other such astrological aspects are listed. The Kundali is a
          co-existence of predictions and charts that help the astrologer learn
          about what your future would be like, the times you will have to grind
          harder, and also the times when luck will be by your side in
          abundance.
        </p>
        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          However, with the culture shift, people are getting very tech-savvy
          and seek things under their thumb. And for the same reason, the Janam
          Kundali making process has also gone online, making it more
          pocket-friendly, accessible, and detailed. We at AstroTalk, for the
          convenience of the huge user base of ours, provide them with free
          Janam kundali online so anyone and everyone has the luxury of learning
          more about themselves and thus can plan their life better
        </p>
        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          However, we also often bump into people who wonder if this online
          kundali astrology is accurate and if it has the same essence of the
          physical Kundli making process? Or is getting a Kundli made by a
          pandit or Vedic astrologer still the best option? To be honest, a
          Kundli made by a Vedic astrologer surely takes the top spot (which our
          astrologers can make for you), but an online Kundli software is no
          less when it comes to precision. Honestly, an online Kundli can be
          more detailed when compared to the physical ones.
        </p>
        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          To state the obvious, just like a physical Kundli is made by learned
          Vedic astrologers and pandits, similarly, an online Kundli too is also
          prepared as per the astrological predictions by the astrologers. What
          changes is only the medium of sharing that information with you, i.e.
          digital? The digitalisation of Kundli is to only allow you more
          benefits like the availability of information in multiple languages.
        </p>
        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          And anyway, one can always get their physical Janam Kundali report
          made by our astrologers, which will reach you physically.
        </p>

        <h1 class="text-sm md:text-lg lg::text-xl text-black font-bold my-2 mx-auto">
          How To Create a Free Online Janam Kundli
        </h1>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          Creating an online Kundli for yourself is relatively easy. Honestly,
          butter-easy. All you need to do to make your online Janam Kundli with
          AstroTalk is simply go to the ‘Kundli’ option and Insert the requested
          information. The requested information would be your date of birth,
          time of your birth, etc. The correct date and time allow precise
          predictions.
        </p>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          Once you have filled in the details, all you need to do is click
          submit. Once you click submit, within seconds, you will find a
          detailed Janam Kundli of yourself. Besides textual content (that will
          help you understand what strong or weak traits you have), the Janam
          Kundli will also help you with charts that will showcase the position
          of the different planets in houses based on your current astrological
          situation, which reveals a whole new dimension of information.
        </p>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          The free Janam Kundli you get on AstroTalk, though, is made after a
          precise study of all the scientific methods but when it comes to
          writing it down, the study is portrayed in a very simple language so
          you can understand it without any hassle. However, just in case you
          hit a snag or can’t understand what it means, simply chat or ring our
          astrologers to help you with your queries
        </p>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          Besides getting a Janam Kundli for yourself, we also offer Janam
          Kundli matching service to anyone seeking. Janam kundli matching is
          most important when one plans to get married. Matching of kundlis is
          done to ensure the girl and boy planning to get married are compatible
          with each other. This, however, doesn’t mean that if kundli doesn't
          match one shouldn’t marry the other person. But in case kundli doesn't
          match, an astrologer can let you know why it did not, and also
          recommend remedies for the same.
        </p>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          Janam Kundli matching is related to Gun matching. Janam Kundli of two
          people, when matched, shows how many guns of those people match. Based
          on this, marriages are fixed. With Janam Kundli matching, you can also
          understand how being Manglik of two people will affect each other,
          what could be the effect of Manglik dosh on you, and remedies to get
          rid of Manglik dosha.
        </p>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          These were just a handful of times when Janam Kundli could come in
          handy. So if looking to get one for yourself, get in touch with our
          astrologers for the best advice and service.
        </p>

        <p class="para mt-2 text-justify text-[10px] md:text-lg lg::text-2xl mx-auto">
          To get your online Janam Kundli, Kundli prediction, and kundali
          reading, you can either chat with our astrologer or talk to our
          astrologer. For free online Kundli making, you can also visit the free
          Janam Kundli chart option and get a personalised free Janam kundali
          for yourself by our Janam Kundli maker software. You can get free
          kundali check and free Kundli prediction.
        </p>
      </div>

      
    
    </div>
  )
}

export default KundliMatching


