import React, { useState } from "react";
import { FaStar, FaPhoneAlt, FaComments } from "react-icons/fa";
import { useAuth } from "../contexts/AuthContext";
import Spinner from "../components/Spinner";
import { useNavigate } from "react-router-dom";
import useOnCallChatClickHandler from "../hooks/useOnCallChatClickHandler";

function AstrologerCard({
  avatar,
  name,
  id,
  rating,
  experience,
  languages,
  specializations,
  minimumCallDuration,
  minutePrice,
  inCall,
  inChat,
  availability,
  showCallChat = true,
}) {
  const [loading, setLoading] = useState(false);
  const { token } = useAuth();

  const navigate = useNavigate();

  const stars = Array.from({ length: 5 }, (v, i) => (
    <FaStar
      key={i}
      className={`${
        i < rating ? "text-yellow-300" : "text-gray-300"
      } inline-block text-xs sm:text-sm md:text-lg`}
    />
  ));

  const onCallChatClickHandler = useOnCallChatClickHandler(
    token,
    setLoading,
    minimumCallDuration,
    minutePrice
  );

  function onCardClickHandler(id) {
    navigate(`/astrologer/${id}`);
  }

  return (
    <div className="w-full mx-auto bg-white shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl">
      <div className="flex gap-2 px-4 py-6 w-full">
        <div className="flex flex-col items-center w-fit">
          <div
            className="cursor-pointer"
            onClick={() => onCardClickHandler(id)}
          >
            <img
              className="rounded-full w-16 h-16 md:h-16 md:w-16 xl:w-20 xl:h-20 object-cover hover:opacity-85"
              src={"https://astrologerdaddy.com/api/" + avatar}
              alt={name.split(" ")[0]}
            />
          </div>
          <div className="flex gap-1 items-center mt-2">{stars}</div>

          <p className="mt-4 text-xs sm:text-sm lg:text-base text-gray-600 font-thin">
            Price: ₹{minutePrice}/min
          </p>
        </div>

        {/* Second div: Name, Languages, Experience, Specializations, and Price */}
        <div className="flex flex-wrap flex-col justify-center w-full">
          <div
            className="cursor-pointer "
            onClick={() => onCardClickHandler(id)}
          >
            <h2 className="text-lg md:text-xl xl:text-xl font-semibold text-gray-800 hover:text-gray-500">
              {name.split(" ")[0]}
            </h2>
          </div>
          <p className="mt-2 text-gray-600 text-sm lg:text-base font-normal">
            {languages.join(", ")}
          </p>
          <p className="text-gray-600 text-sm lg:text-base font-normal">
            Exp: {experience} years
          </p>

          <div className="text-gray-600 text-sm lg:text-base font-normal max-w-full overflow-hidden text-ellipsis whitespace-nowrap">
            {specializations.map(
              (specialization, index) =>
                index <= 3 && (
                  <span
                    key={index}
                    className="inline-block max-w-full overflow-hidden text-ellipsis whitespace-nowrap"
                    title={specialization.name}
                  >
                    {specialization.name}
                    {index !== specializations.length - 1 && ", "}
                  </span>
                )
            )}
          </div>

          {showCallChat && (
            <div className="px-1 py-1 flex gap-2 justify-between flex-wrap mx-auto">
              <button
                onClick={() => {
                  onCallChatClickHandler(id, "call");
                }}
                className="flex items-center px-2 py-2 lg:mt-4 border-2 text-white font-semibold rounded-lg hover:bg-opacity-80 focus:outline-none bg-yellow-500"
                disabled={loading}
              >
                <span
                  className={`w-2 h-2 rounded-full inline-block mr-2 ${
                    !availability
                      ? "bg-red-500"
                      : inCall || inChat
                      ? "bg-yellow-700"
                      : "bg-green-600"
                  }`}
                ></span>
                <FaPhoneAlt className="mr-2" />
                Call
                
              </button>
              <button
                onClick={() => {
                  onCallChatClickHandler(id, "chat");
                }}
                className="flex items-center px-2 py-2 lg:mt-4 border-2 text-white font-semibold rounded-lg hover:bg-opacity-80 focus:outline-none bg-yellow-500"
              >
                <span
                  className={`w-2 h-2 rounded-full inline-block mr-2 ${
                    !availability
                      ? "bg-red-500"
                      : inCall || inChat
                      ? "bg-yellow-700"
                      : "bg-green-600"
                  }`}
                ></span>
                <FaComments className="mr-2" />
                Chat
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AstrologerCard;
