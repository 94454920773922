import { useState, useEffect } from "react";
import api from "../api";
import Spinner from "../components/Spinner";
import { useAuth } from "../contexts/AuthContext";
import { Link } from "react-router-dom";
import dayjs from "dayjs";

function Appointments() {
  const { token } = useAuth();

  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10); // items per page
  const [sortField, setSortField] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [search, setSearch] = useState("");

  useEffect(() => {
    fetchAppointments();
  }, [token, currentPage, limit, sortField, sortOrder, search]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const fetchAppointments = async () => {
    setIsLoading(true);
    setError(""); // Clear previous errors
    try {
      const response = await api.get("/users/appointments", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page: currentPage,
          limit,
          sortField,
          sortOrder,
          search,
        },
      });

      setAppointments(response.data.appointments);
      setTotalPages(Math.ceil(response.data.totalCount / limit));
    } catch (error) {
      console.error("Failed to fetch appointments:", error);
      setError("Failed to fetch appointments.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortChange = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page when searching
  };

  // Logic to render limited page numbers
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = 5;
    const halfWindow = Math.floor(maxPageNumbersToShow / 2);
    let startPage = Math.max(currentPage - halfWindow, 1);
    let endPage = Math.min(currentPage + halfWindow, totalPages);

    if (endPage - startPage < maxPageNumbersToShow - 1) {
      startPage = Math.max(endPage - maxPageNumbersToShow + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-4 py-2 mx-1 text-white rounded ${
            currentPage === i
              ? "bg-yellow-600"
              : "bg-yellow-600 hover:bg-yellow-800"
          }`}
        >
          {i}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div className="container w-11/12 md:w-5/6 mx-auto my-10">
      <p className="font-bold text-lg text-center md:text-left ml-2 mb-8">
        Appointments
      </p>

      <div className="flex justify-between items-center mb-8 px-4">
        <input
          type="text"
          placeholder="Search appointments..."
          value={search}
          onChange={handleSearchChange}
          className="px-4 py-2 border rounded-lg w-full md:w-1/2"
        />
      </div>

      {isLoading ? (
        <div className="flex justify-center my-20">
          <Spinner className="w-8 h-8" />
        </div>
      ) : error ? (
        <p className="text-red-500 text-center">{error}</p>
      ) : appointments.length === 0 ? (
        <p className="text-center text-gray-500">No data to show.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {appointments.map((appointment, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 border border-gray-200"
            >
              <p
                className={`font-bold ${
                  appointment.status === "Completed"
                    ? "text-green-600"
                    : appointment.status === "Pending"
                    ? "text-yellow-600"
                    : "text-red-600"
                }`}
              >
                {appointment.status}
              </p>

              {appointment.status === "Pending" &&
                appointment.accepted === "Accepted" &&
                (appointment.type === "call" ? (
                  <Link
                    to={`/user/appointment/call/${appointment._id}`}
                    className={`text-sm font-semibold bg-orange-600 hover:bg-orange-400 px-2 rounded text-white py-1`}
                  >
                    Rejoin Call
                  </Link>
                ) : (
                  <Link
                    to={`/user/appointment/chat/${appointment._id}`}
                    className={`text-sm font-semibold bg-orange-600 hover:bg-orange-400 px-2 rounded text-white py-1`}
                  >
                    Rejoin Chat
                  </Link>
                ))}

              <div className=" font-semibold text-black">
                Astrologer consultation with {appointment.astrologer?.name}
              </div>
              <p className="text-gray-500 text-sm mt-2">
                {dayjs(appointment.createdAt).format("DD/MM/YYYY hh:mm A")}
              </p>
              <p className="text-gray-500 text-sm mt-2">
                Duration: {appointment.duration} minutes
              </p>
              <p className="text-gray-500 text-sm mt-2">
                Total Charged Amount: &#8377;{" "}
                {appointment.transactions
                  .reduce((total, element) => total + element.amount, 0)
                  .toFixed(2)}
              </p>
            </div>
          ))}
        </div>
      )}

      {/* Pagination */}
      {appointments.length > 0 && (
        <div className="flex justify-center my-8">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
            className="px-4 py-2 mx-1 text-white bg-orange-800 rounded disabled:bg-gray-400 hover:bg-orange-500"
          >
            Previous
          </button>
          {renderPageNumbers()}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 mx-1 text-white bg-orange-800 rounded disabled:bg-gray-400 hover:bg-orange-500"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
}

export default Appointments;
